import { useFormContext } from "react-hook-form";

interface Props {
    label: string;
    onClick?: () => void;
    className?: string;
    type: "button" | "submit" | "reset";
    appearance: "primary" | "secondary";
    disabled?: boolean;
}

const Button = (props: Props) => {
    const ctx = useFormContext();
    const isSubmitting = ctx?.formState?.isSubmitting || false;

    const primaryClass = "text-white text-sm rounded bg-[#1a9088] hover:bg-[#1ea49b] py-2 px-5";
    const secondaryClass = "text-white text-sm rounded bg-gray-400 hover:bg-gray-300 py-2 px-5";

    return (
        <button
            disabled={
                props.disabled || (props.type === "submit" && isSubmitting)
            }
            type={props.type}
            onClick={props.onClick}
            className={`
                ${props.appearance === "primary" ? primaryClass : ""}
                ${props.appearance === "secondary" ? secondaryClass : ""}
                ${props.className || ""}
            `}
        >
            {props.label}
        </button>
    );
};

export default Button;
