const substr = (str: string, length: number = 25): string => {
    if (str.length <= length) {
        return str;
    }

    return str.substring(0, length) + "...";
};

const striptags = (str: string): string => {
    return str.replace(/(<([^>]+)>)/gi, "");
};

const route = (route: string, params?: any): string => {
    for (const key in params) {
        route.replace(`:${key}`, params[key]);
    }

    return route;
};

export {
    substr,
    striptags,
    route
}
