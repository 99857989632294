import Button from "components/Forms/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { messageStore } from "stores/MessageStore";
import PageNotFound from "../PageNotFound";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

const MessagesView = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        loadMessage();
    }, []);

    const loadMessage = async () => {
        setLoading(true);

        await messageStore.single(id);

        setLoading(false);
    };

    const message = messageStore.message;

    return (
        <div>
            {loading ? (
                "Loading..."
            ) : message ? (
                <>
                    <div className="flex justify-between mb-8">
                        <Button type="button" appearance="secondary" label="Annuller" onClick={() => navigate("/")} />
                        <Button type="button" appearance="primary" label="Edit message" onClick={() => navigate(`/messages/${id}/edit`)} />
                    </div>
                    <div className="bg-white rounded-lg p-4">
                        <dl>
                            <dt className="font-bold">Title</dt>
                            <dd className="mb-4">{message.title}</dd>
                            <dt className="font-bold">Body</dt>
                            <dd className="mb-4" dangerouslySetInnerHTML={{ __html: message.body }} />
                            <dt className="font-bold">Expires at</dt>
                            <dd className="mb-4">{message.expiresAt ? dayjs(message.expiresAt).format("DD/MM/YYYY @ HH:mm") : null}</dd>
                            <dt className="font-bold">Archived</dt>
                            <dd className="mb-4">{message.isArchived ? "Yes" : "No"}</dd>
                        </dl>
                        <p className="text-gray-400 text-xs mb-2">
                            <span className="font-bold">ID:</span> {message.id}
                        </p>
                        <p className="text-gray-400 text-xs mb-2">
                            <span className="font-bold">Created:</span> {message.createdAt ? dayjs(message.createdAt).format("DD/MM/YYYY @ HH:mm") : "(Unknown)"}
                        </p>
                        <p className="text-gray-400 text-xs">
                            <span className="font-bold">Last updated:</span> {message.updatedAt ? dayjs(message.updatedAt).format("DD/MM/YYYY @ HH:mm") : "(Unknown)"}
                        </p>
                    </div>
                </>
            ) : message === false ? (
                <PageNotFound />
            ) : null}
        </div>
    );
});

export default MessagesView;
