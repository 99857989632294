import { useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import Error from "components/Forms/Error";
import Label from "components/Forms/Label";
import { useEffect } from "react";

interface Props extends FormElementInterface {
    type?: 'text' | 'password' | 'checkbox' | 'email' | 'date' | 'datetime-local',
    defaultChecked?: boolean,
    className?: string,
    inputClassName?: string,
    min?: string,
    maxLength?: number,
    step?: string
}

const Input = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    return (
        <div className={props.className || 'mb-4'}>
            <Label name={props.name} label={props.label} />
            <input
                className={props.inputClassName || 'border p-2 rounded'}
                {...register(props.name)}
                type={props.type || 'text'}
                defaultChecked={props.defaultChecked}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                min={props.min}
                maxLength={props.maxLength}
                step={props.type === 'datetime-local' ? props.step : '60'}
            />
            <Error name={props.name} />
        </div>
    )
}

export default Input;
