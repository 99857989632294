import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { messageStore } from "stores/MessageStore";
import MessageForm from "./Form";
import MessageInterface from "types/MessageInterface";

const MessagesCreate = () => {
    const navigate = useNavigate();

    const onSubmit = async (message: MessageInterface) => {
        if (!message.isArchived && !(window as any).confirm("Are you sure?")) {
            return;
        }

        const result : boolean = await messageStore.create({
            title: message.title,
            teaser: message.teaser,
            body: message.body,
            expiresAt: message.expiresAt,
            isArchived: message.isArchived,
        });

        if (result) {
            toast("Message created", { type: "success" });

            navigate("/");
        } else {
            toast("An error occurred", { type: "error" });
        }
    };

    return (
        <div>
            <MessageForm title="" teaser="" body="" onSubmit={onSubmit} />
        </div>
    );
};

export default MessagesCreate;
