interface Props {
    name?: string,
    label?: string | React.ReactNode
}

const Label = (props: Props) => {
    if (!props.label) return null;

    return (
        <label
            className='text-sm font-light block mb-1 tracking-wider'
            htmlFor={props.name}>
            {props.label}
        </label>
    )
}

export default Label;
