import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Button from "components/Forms/Button";
import { messageStore } from "stores/MessageStore";
import { substr, striptags } from "helpers";
import MessageInterface from "types/MessageInterface";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";

const MessagesList = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("desc");

    useEffect(() => {
        loadMessages();
    }, [sort]);

    const loadMessages = async () => {
        setLoading(true);

        await messageStore.list(sort);

        setLoading(false);
    };

    const removeMessage = async (id?: string) => {
        if (!window.confirm("Are you sure?")) {
            return;
        }

        const result = await messageStore.remove(id);

        if (result === true) {
            toast("Message deleted", { type: "success" });

            loadMessages();
        } else {
            toast("An error occurred", { type: "error" });
        }
    };

    const messages = messageStore.messages;

    return (
        <div>
            <div className="flex justify-end mb-8">
                <Button type="button" appearance="primary" label="Create message" onClick={() => navigate("/messages/create")} />
            </div>
            <div className="bg-white rounded-lg p-4">
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="text-left font-semibold text-sm pb-2 w-1/4">Title</th>
                            <th className="text-left font-semibold text-sm pb-2 w-1/4">Teaser</th>
                            <th className="text-left font-semibold text-sm pb-2 w-1/4">Body</th>
                            <th className="text-left font-semibold text-sm pb-2 w-1/4 cursor-pointer" onClick={() => setSort(sort === "desc" ? "asc" : "desc")}>Expires at {sort === "desc" ? "↓" : "↑"}</th>
                            <th className="text-left font-semibold text-sm pb-2">Archived</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={6} className="border-t border-gray-100 py-2">
                                    Loading messages...
                                </td>
                            </tr>
                        ) : messages.map((item: MessageInterface) => (
                            <tr key={item.id} className="group">
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer">
                                    {substr(item.title)}
                                </td>
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer">
                                    {item.teaser && substr(item.teaser)}
                                </td>
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer">
                                    {substr(striptags(item.body), 25)}
                                </td>
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer">
                                    {dayjs(item.expiresAt).format("DD/MM/YYYY @ HH:mm")}
                                </td>
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer">
                                    {item.isArchived ? "✅" : ""}
                                </td>
                                <td onClick={() => navigate(`/messages/${item.id}`)} className="border-t border-gray-100 group-hover:bg-gray-100 py-2 cursor-pointer text-right">
                                    <button
                                        onClick={(event: MouseEvent) => {
                                            event.stopPropagation();
                                            removeMessage(item.id);
                                        }}
                                        className="border-0 rounded bg-red-600 hover:bg-red-500 text-white text-xs px-1 py-0 ml-1"
                                        title="Delete"
                                    >
                                        <i className="material-icons text-base">clear</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default MessagesList;
