import { doc, addDoc, getDoc, getDocs, setDoc, deleteDoc, getFirestore, collection, query, orderBy, OrderByDirection } from "firebase/firestore";
import MessageInterface from "types/MessageInterface";
import dayjs from "dayjs";
import { notificationHub } from "helpers/NotificationHub";
import { action, makeObservable, observable, runInAction } from "mobx";

class MessageStore {
    messages: Array<MessageInterface> = [];
    message: MessageInterface | null = null;

    constructor() {
        makeObservable(this, {
            messages: observable,
            message: observable,
            list: action,
            single: action,
        });
    }

    create = async (message: MessageInterface): Promise<boolean> => {
        try {
            const docRef = await addDoc(collection(getFirestore(), "messages"), {
                ...message,
                createdAt: dayjs().toISOString(),
                expiresAt: message.expiresAt ? dayjs(message.expiresAt).toISOString() : null,
            });

            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const doc = docSnap.data();

                try {
                    notificationHub.sendNotification({
                        id: docSnap.id,
                        title: doc.title,
                        teaser: doc.teaser,
                        body: doc.body,
                    });
                } catch (error) {
                    // Only log error to prevent creating identical messages
                    console.log(error);
                }
            }

            return true;
        } catch (error) {
            console.log(error);

            return false;
        }
    };

    list = async (sort: string = "desc") => {
        try {
            const messagesRef = collection(getFirestore(), "messages");
            const q = query(messagesRef, orderBy("expiresAt", sort as OrderByDirection));

            const querySnapshot = await getDocs(q);

            let data: any = [];

            querySnapshot.forEach((doc) => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            runInAction(() => {
                this.messages = data;
            });
        } catch (error) {
            console.log(error);
        }
    };

    single = async (id?: string) => {
        if (!id) {
            return;
        }

        try {
            const docRef = doc(getFirestore(), "messages", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                runInAction(() => {
                    this.message = {
                        id: docSnap.id,
                        ...docSnap.data(),
                    } as MessageInterface;
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    update = async (message: MessageInterface): Promise<boolean> => {
        if (!message.id) {
            return false;
        }

        try {
            const docRef = doc(getFirestore(), "messages", message.id);

            delete message.id;

            await setDoc(
                docRef,
                {
                    ...message,
                    updatedAt: dayjs().toISOString(),
                },
                { merge: true }
            );

            return true;
        } catch (error) {
            console.log(error);

            return false;
        }
    };

    remove = async (id?: string): Promise<boolean> => {
        if (!id) {
            return false;
        }

        try {
            const docRef = doc(getFirestore(), "messages", id);

            await deleteDoc(docRef);

            return true;
        } catch (error) {
            console.log(error);

            return false;
        }
    };
}

export const messageStore = new MessageStore();
