import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const LoggedInContainer = observer(() => {
    const navigate = useNavigate();
    const auth = getAuth();
    const isLoggedIn = authStore.userIsSignedIn;

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn]);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            //console.log("User is logged in", user);
        } else {
            // User is signed out
            authStore.signOut();
        }
    });

    return (
        <div>
            <div className="w-screen min-h-screen bg-[#F5F6FA] p-8">
                <div className="container mx-auto">
                    <div className="flex justify-between items-center mb-8">
                        <h1 className="text-4xl font-bold">Emergency Push</h1>
                        {isLoggedIn ? (
                            <button type="button" onClick={() => authStore.signOut()} className="text-sm text-blue-600 hover:text-blue-500 no-underline hover:underline">
                                Log out
                            </button>
                        ) : null}
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
});

export default LoggedInContainer;
