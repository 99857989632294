import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center">
            <div className="text-center text-gray-500">
                <h1 className="text-6xl font-extralight mb-4">404 Page Not Found</h1>
                <p>
                    The requested resource could not be found.{" "}
                    <button onClick={() => navigate("/")} className="text-blue-500 hover:text-blue-400">
                        Click here
                    </button>{" "}
                    to go home.
                </p>
            </div>
        </div>
    );
};

export default PageNotFound;
