import { getApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import MessageInterface from "types/MessageInterface";

const CryptoJS = require("crypto-js");

class NotificationHub {
    endpoint: string = "";
    token: string = "";

    sendNotification = (message: MessageInterface) => {
        const configEndpointKey = process.env.REACT_APP_AZURE_ENDPOINT_KEY!;
        const configTokenKey = process.env.REACT_APP_AZURE_TOKEN_KEY!;

        const remoteConfig = getRemoteConfig(getApp());

        fetchAndActivate(remoteConfig)
            .then(() => {
                this.endpoint = getValue(remoteConfig, configEndpointKey).asString();

                this.token = this.getSelfSignedToken(this.endpoint, getValue(remoteConfig, configTokenKey).asString(), "RootManageSharedAccessKey", 60 * 24);

                this.makeRequest(
                  {
                      title: message.title,
                      ctype: "EmergencyMessage",
                      cid: "1234",
                      tsr: message.teaser,
                      src: "Emergency App",
                      messageid: message.id,
                  }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    makeRequest = (data: object) => {
        return fetch(this.endpoint, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: this.token,
                "Content-Type": "application/json;charset=utf-8",
            },
        });
    };

    getSelfSignedToken = (targetUri: string, sharedKey: string, ruleId: string, expiresInMins: number): string => {
        targetUri = encodeURIComponent(targetUri.toLowerCase()).toLowerCase();

        // Set expiration in seconds
        const expireOnDate = new Date();
        expireOnDate.setMinutes(expireOnDate.getMinutes() + expiresInMins);

        const expires = Date.UTC(expireOnDate.getUTCFullYear(), expireOnDate.getUTCMonth(), expireOnDate.getUTCDate(), expireOnDate.getUTCHours(), expireOnDate.getUTCMinutes(), expireOnDate.getUTCSeconds()) / 1000;
        const tosign = targetUri + "\n" + expires;

        // using CryptoJS
        const signature = CryptoJS.HmacSHA256(tosign, sharedKey);
        const base64signature = signature.toString(CryptoJS.enc.Base64);
        const base64UriEncoded = encodeURIComponent(base64signature);

        // construct autorization string
        const token = `SharedAccessSignature sr=${targetUri}&sig=${base64UriEncoded}&se=${expires}&skn=${ruleId}`;

        return token;
    };
}

export const notificationHub = new NotificationHub();
