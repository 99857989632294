import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import { action, makeObservable, observable, runInAction } from "mobx";
import SaveableStore from "./SaveableStore";

class AuthStore extends SaveableStore {
    user: User | null = null;

    constructor() {
        super("AuthStore");

        makeObservable(this, {
            user: observable,
            signOut: action,
            signIn: action,
        });

        this.initSessionStorage(this, ["user"]);
    }

    signOut = async () => {
        await getAuth().signOut();

        runInAction(() => {
            this.user = null;
        });
    };

    signIn = async (email: string, password: string) => {
        try {
            const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);

            runInAction(() => {
                this.user = userCredential.user;
            });
        } catch (error) {
            console.log(error);
        }
    };

    get userIsSignedIn() {
        return this.user !== null;
    }
}

export const authStore = new AuthStore();
