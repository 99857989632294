import LoggedInContainer from "context/LoggedInContainer";
import Login from "pages/Login";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from "react-router-dom";
import ToastContainer from "components/ToastContainer";
import MessagesCreate from "pages/Messages/Create";
import MessagesView from "pages/Messages/View";
import MessagesList from "pages/Messages/List";
import MessagesEdit from "pages/Messages/Edit";

export const history = createBrowserHistory();

const MyRoutes = () => (
    <div>
        <ToastContainer position="top-center" autoClose={2000} />
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<LoggedInContainer />}>
                    <Route path="/" element={<MessagesList />} />
                    <Route path="/messages/create" element={<MessagesCreate />} />
                    <Route path="/messages/:id" element={<MessagesView />} />
                    <Route path="/messages/:id/edit" element={<MessagesEdit />} />
                </Route>
            </Routes>
        </HistoryRouter>
    </div>
);

export default MyRoutes;
