import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { useNavigate } from "react-router-dom";
import { YupInterface } from "types/YupInterface";
import dayjs from "dayjs";
import Tiptap from "components/Forms/Tiptap";
import MessageInterface from "types/MessageInterface";

interface Props extends MessageInterface {
    onSubmit: (data: any) => void;
}

const MessageForm = (props: Props) => {
    const navigate = useNavigate();

    const validation = (yup: YupInterface) => {
        return {
            title: yup.string().max(50, "Titel må max være ${max} tegn").required("Titel skal udfyldes"),
            teaser: yup.string().max(150, "Teaser tekst må max være ${max} tegn"),
            body: yup.string().required("Besked skal udfyldes"),
        };
    };

    const dateFormat = "YYYY-MM-DDTHH:mm";

    let defaultDate = dayjs().add(1, "day").minute(0).second(0);
    let minimumDate = dayjs().add(1, "hour").minute(0).second(0);

    if (props.expiresAt) {
        defaultDate = dayjs(props.expiresAt);

        if (minimumDate.isAfter(defaultDate)) {
            minimumDate = defaultDate;
        }
    }

    return (
        <div>
            <Form onSubmit={props.onSubmit} validation={validation}>
                <div className="flex justify-between mb-8">
                    <Button type="button" appearance="secondary" label="Cancel" onClick={() => navigate("/")} />
                    <Button type="submit" appearance="primary" label={props.id ? "Save" : "Send"} />
                </div>
                <div className="bg-white rounded-lg p-4">
                    <div>
                        <Input type="text" name="title" label="Title" maxLength={50} defaultValue={props.title} inputClassName="w-full border p-2 rounded" />
                    </div>
                    <div>
                        <Input type="text" name="teaser" label="Teaser" maxLength={150} defaultValue={props.teaser} inputClassName="w-full border p-2 rounded" />
                    </div>
                    <div>
                        <Tiptap name="body" label="Body" defaultValue={props.body} />
                    </div>
                    <div>
                        <Input type="datetime-local" name="expiresAt" label="Expires at" defaultValue={defaultDate.format(dateFormat)} min={minimumDate.format(dateFormat)} step="3600" inputClassName="border p-2 rounded" />
                    </div>
                    <div>
                        <Input type="checkbox" name="isArchived" defaultChecked={props.isArchived} label="Archived" />
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default MessageForm;
