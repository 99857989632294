import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";
import { YupInterface } from "types/YupInterface";

const PageLogin = () => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.userIsSignedIn) {
            navigate("/");
        }
    }, []);

    const onSubmit = async (data: any) => {
        setIsLoggingIn(true);

        try {
            await authStore.signIn(data["username"], data["password"]);

            navigate("/");
        } catch (error) {
            toast("Invalid login", { type: "error" });
        } finally {
            setIsLoggingIn(false);
        }
    };

    const validation = (yup: YupInterface) => {
        return {
            username: yup.string().required("Email is required"),
            password: yup.string().required("Password is required"),
        };
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-[#F5F6FA]">
            <div className="w-96 p-10 bg-white rounded-md shadow">
                <Form onSubmit={onSubmit} validation={validation}>
                    <Input label="Email" name="username" placeholder="john@doe.com" inputClassName="w-full border p-2 rounded" />
                    <Input type="password" label="Password" name="password" inputClassName="w-full border p-2 rounded" />
                    <div className="text-right">
                        <Button label={isLoggingIn ? "Logging in..." : "Log in"} type="submit" appearance={isLoggingIn ? "secondary" : "primary"} className="w-full" />
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default PageLogin;
