import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Error from "../Error";
import Label from "../Label";

import "./styles.scss";

interface TiptapMenubarProps {
    editor: any;
}

interface TiptapProps {
    name: string;
    label: string;
    defaultValue?: string;
    onUpdate?: (editor: any) => void;
}

const MenuBar = (props: TiptapMenubarProps) => {
    const editor = props.editor;

    if (!editor) {
        return null;
    }

    return (
        <div className="tiptap-buttons">
            <button type="button" onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive("bold") ? "is-active" : ""}>
                <i className="material-icons">format_bold</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive("italic") ? "is-active" : ""}>
                <i className="material-icons">format_italic</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive("underline") ? "is-active" : ""}>
                <i className="material-icons">format_underlined</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive("strike") ? "is-active" : ""}>
                <i className="material-icons">format_strikethrough</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive("paragraph") ? "is-active" : ""}>
                <i className="material-icons">text_fields</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}>
                <i className="material-icons">title</i>1
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}>
                <i className="material-icons">title</i>2
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}>
                <i className="material-icons">title</i>3
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}>
                <i className="material-icons">title</i>4
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive("bulletList") ? "is-active" : ""}>
                <i className="material-icons">format_list_bulleted</i>
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive("orderedList") ? "is-active" : ""}>
                <i className="material-icons">format_list_numbered</i>
            </button>
            {/* <button type="button" onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive("codeBlock") ? "is-active" : ""}>
                code block
            </button>
            <button type="button" onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive("blockquote") ? "is-active" : ""}>
                blockquote
            </button>
            <button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
            <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button>
            <button type="button" onClick={() => editor.chain().focus().undo().run()}>undo</button>
            <button type="button" onClick={() => editor.chain().focus().redo().run()}>redo</button>
            <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>clear marks</button>
            <button type="button" onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</button> */}
        </div>
    );
};

const TiptapEditor = (props: TiptapProps) => {
    const { unregister, setValue } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name);
        };
    }, []);

    const editor = useEditor({
        extensions: [StarterKit, Underline],
        content: props.defaultValue,
        onCreate: ({ editor }) => {
            setValue(props.name, editor.isEmpty ? "" : editor.getHTML());
        },
        onUpdate: ({ editor }) => {
            setValue(props.name, editor.isEmpty ? "" : editor.getHTML());

            if (props.onUpdate !== undefined) {
                props.onUpdate(editor);
            }
        },
    });

    return (
        <div className="mb-4">
            <Label name={props.name} label={props.label} />
            <div className="border border-gray-200 rounded">
                <MenuBar editor={editor} />
                <div className="p-4">
                    <EditorContent editor={editor} />
                </div>
            </div>
            <Error name={props.name} />
        </div>
    );
};

export default TiptapEditor;
