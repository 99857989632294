import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { messageStore } from "stores/MessageStore";
import PageNotFound from "../PageNotFound";
import MessageForm from "./Form";
import MessageInterface from "types/MessageInterface";

const MessagesEdit = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        loadMessage();
    }, []);

    const loadMessage = async () => {
        setLoading(true);

        await messageStore.single(id);

        setLoading(false);
    };

    const onSubmit = async (message: MessageInterface) => {
        if (!message.isArchived && !(window as any).confirm("Are you sure?")) {
            return;
        }

        const result: boolean = await messageStore.update({
            id,
            ...message,
        });

        if (result === true) {
            toast("Message updated", { type: "success" });

            navigate("/");
        } else {
            toast("An error occurred", { type: "error" });
        }
    };

    const message = messageStore.message;

    return <div>{loading ? "Loading..." : message ? <MessageForm id={message.id} title={message.title} teaser={message.teaser} body={message.body} expiresAt={message.expiresAt} isArchived={message.isArchived} onSubmit={onSubmit} /> : message === false ? <PageNotFound /> : null}</div>;
});

export default MessagesEdit;
